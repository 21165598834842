import React, { Component } from "react";
import { accessControl } from "../../../permission/permission";
import SimpleFileUpload from "react-simple-file-upload";
import { simpleFileUploadAPIKey, simpleFileUploadFolderName } from "../../../resources/url";

class PhotoForm extends Component {
    constructor(props) {
        super(props);
        accessControl([1, 4]);
    }
    render() {
        return (
            <form onSubmit={this.props.handleSubmit}>
                <div className="card-body" style={{ textAlign: 'left' }}>
                    <div className="me-n7 pe-7" id="kt_modal_new_address_scroll text-left" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

                        <div className="row mb-5">
                            <div className="col-md-6 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Category</label>
                                <select
                                    className="form-control form-control-solid"
                                    name="category"
                                    id="category"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.category}
                                >
                                    <option value="">Select category</option>
                                    {
                                        this.props.categoryList.map((category, index) => {
                                            return <option key={index} data={category.EntryID} value={category.EntryID}>{category.CategoryName}</option>
                                        })
                                    }
                                </select>
                            </div>

                            <div className="col-md-6 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Sub Category</label>
                                <select
                                    className="form-control form-control-solid"
                                    name="SubCategoryID"
                                    id="SubCategoryID"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.SubCategoryID}
                                >
                                    <option value="">Select subcategory</option>
                                    {
                                        this.props.subCategoryList.map((category, index) => {
                                            return <option key={index} data={category.EntryID} value={category.EntryID}>{category.SubCategoryName}</option>
                                        })
                                    }
                                </select>
                            </div>

                            <div className="col-md-6 fv-row mt-6">
                                <label className="required fs-5 fw-bold mb-2">Image</label>

                                {/*<SimpleFileUpload*/}
                                {/*    apiKey={simpleFileUploadAPIKey}*/}
                                {/*    maxFileSize={1}*/}
                                {/*    tag={simpleFileUploadFolderName+`-gallery`}*/}
                                {/*    onSuccess={this.props.onImageChange}*/}
                                {/*    preview="false"*/}
                                {/*    width="100%"*/}
                                {/*    height="100"*/}
                                {/*/>*/}
                                <div className="mb-3">
                                    <div className="fv-row mb-2">
                                        <div className="dropzone" id="kt_ecommerce_add_product_media" onClick={()=>{
                                            document.getElementById("photo").click()
                                        }}>
                                            <div className="dz-message needsclick">
                                                <i className="bi bi-image text-primary fs-3x"/>
                                                <div className="ms-4 col-md-9">
                                                    <h3 className="fs-5 fw-bold text-gray-900 mb-1">Click to upload.</h3>
                                                    <span className="fs-7 fw-semibold text-gray-400 text-info"> {this.props.data.image_name !== "" ? this.props.data.image_name : "Only .jpg, .png, .jpeg are allowed"}</span>
                                                </div>
                                                <div className="col-md-2">{this.props.image !== "" ? <img className="img-thumbnail" width={120} height={100} srcSet={this.props.image !== "" ? this.props.image : ""}/> : ""}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <span className="alert-danger"> Max of 5MB file is allowed!</span>
                                </div>
                                <input type="file"  id="photo" name={"photo"}  accept={"image/*"} onChange={this.props.onImageChange} hidden />
                            </div>
                            <div className="col-md-6 fv-row mt-6">
                                <label className="required fs-5 fw-bold mb-2">Image Title</label>
                                <input
                                    type="text"
                                    className="form-control form-control-solid  mb-5"
                                    name="ImageTitle"
                                    id="ImageTitle"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.ImageTitle}
                                    placeholder="Image Title"
                                />
                            </div>

                        </div>



                        <button type="submit" id="kt_modal_new_address_submit" data-kt-indicator={this.props.isLoading} className="btn btn-primary w-25 offset-sm-4 mb-9 mt-9">
                            <span className="indicator-label">Submit</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2" />
                            </span>
                        </button>
                    </div>
                </div>
            </form>
        );
    }
}

export default PhotoForm;