import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
    setgeneralDetails
} from "../../../actions/setgeneraldetailsaction";
import { serverLink } from "../../../resources/url";
import { Oval } from "react-loader-spinner";
import { connect } from "react-redux";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import axios from "axios";
import Dateformat from "../../util/dateformat";
import Header from "../../Header/header";

class HeadlinesList extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: true,
            columns: [
                {
                    name: "Link Title",
                    selector: row => `${row.LinkTitle}`,
                    sortable: true,
                    width: '25%'
                },
                {
                    name: "Link Path",
                    // selector: row => `${row.LinkPath}`,
                    cell: (row) => (
                        <a target="_blank" href={row.LinkPath}>View Link</a>
                    ),
                    sortable: true
                },
                {
                    name: "BG Colour",
                    selector: row => `${row.BGColour}`,
                    sortable: true
                },
                // {
                //     name: "Message",
                //     selector: row => `${row.Message}`,
                //     sortable: true
                // },
                {
                    name: "Message Date",
                    selector: row => <Dateformat date={row.MessageDate} />,
                    sortable: true
                },
                {
                    name: "View",
                    cell: (row) => (
                        <a href={`babaahmeduniversity.edu.ng/headline/${row.Slug}`} target="_blank" className="btn btn-primary btn-sm">
                            <i className="fa fa-eye" aria-hidden="true" />
                        </a>

                    ),
                },
                {
                    name: "Action",
                    cell: (row) => (
                        <Link to="/general/headline/update">
                            <button
                                type="button"
                                onClick={async () => {
                                    const headlineData = {
                                        ...row.rowData
                                    };
                                    this.props.setOnGeneralDetails(headlineData);
                                }}
                                className="btn btn-primary btn-sm"
                            >
                                <i className="fa fa-edit" aria-hidden="true" />
                            </button>
                        </Link>
                    ),
                },

            ],
            data: []
        }
    }

    componentDidMount() {
        this.getHeadlineData();
    }

    async getHeadlineData() {
        await axios.get(`${serverLink}news/headline_list/all`)
            .then(data => {
                const result = data.data;
                result.map((data, index) => {
                    this.setState({
                        data: [...this.state.data,
                        {
                            LinkTitle: data.LinkTitle,
                            LinkPath: data.LinkPath,
                            BGColour: data.BGColour,
                            Message: data.Message,
                            MessageDate: data.MessageDate,
                            Slug: data.Slug,
                            rowData: data
                        }
                        ]

                    });

                })

            });
        this.setState({ isLoading: false })
    }


    render() {
        let count = 1;
        const columns = this.state.columns;
        const data = this.state.data;
        const tableData = {
            columns,
            data,
            print: false,
            exportHeaders: true,
            "export": true,
            filterPlaceholder: 'Search Headline News',
        };
        return (
            <>
                <Header isFaculty={false} />
                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                    <div className="toolbar" id="kt_toolbar">
                        <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                            <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                                <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">General
                                    <span className="h-20px border-dark  border-start ms-3 mx-2" />
                                    Headline
                                    <span className="h-20px border-dark  border-start ms-3 mx-2" />
                                    Headline List
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="post d-flex flex-column-fluid" id="kt_post">
                        <div id="kt_content_container" className="container-fluid">
                            {/* CONTENT START */}
                            {this.state.isLoading ?
                                <div style={{
                                    margin: 'auto',
                                    position: 'relative',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                    height: '400px'
                                }}>
                                    <Oval
                                        type="Oval"
                                        color="#eeeeee"
                                        height={50}
                                        width={50}
                                        secondaryColor="teal"
                                        timeout={15000} //3 secs
                                    />
                                </div>
                                :
                                <div className="card mb-5 mb-xl-10">
                                    <div className="card-header">
                                        <div className="card-title">
                                            <h3>HEADLINE LIST</h3>
                                        </div>
                                        <span className="mt-5" style={{ float: 'right' }}>
                                            <Link to="/general/headline/addheadline" className="btn btn-primary">
                                                <i className="fa fa-plus"></i> Add Headline
                                            </Link>
                                        </span>

                                    </div>

                                    <div className="card-body" style={{ textAlign: 'left' }}>
                                        <DataTableExtensions
                                            {...tableData}
                                        >
                                            <DataTable
                                                noHeader
                                                defaultSortField="id"
                                                defaultSortAsc={false}
                                                pagination
                                                highlightOnHover />
                                        </DataTableExtensions>

                                    </div>

                                </div>}

                            {/* CONTENT END */}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapDisptachToProps = (dispatch) => {
    return {
        setOnGeneralDetails: (p) => {
            dispatch(setgeneralDetails(p));
        },
    };
};


export default connect(null, mapDisptachToProps)(HeadlinesList);