import React, {Component} from "react";
import { Oval } from  'react-loader-spinner'
import {serverLink} from "../../resources/url";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import axios from "axios";
import Header from "../Header/header";
import XMLParser from 'react-xml-parser';
import swal from "sweetalert";
class BazeInMedia extends Component {
    constructor() {
        super();
        this.state = {
            checkedNews:[],
            data: [],
            sendData: [],
            isLoading: true,
            columns: [
              // {
              //   name: "SN",
              //   cell: (row, index) => index+1,
              //   width:'5%'
              // },
              {
                name: "Action",
                width: '5%',
                cell: (row) => (
                  <input type="checkbox"
                         id="selectedNews"
                         name="selectedNews"
                         value="selectedNews"
                         onChange={(e)=>{this.handleChange (e, row)}}
                  />
                ),
              },
                {
                    name: "Title",
                    selector: row => `${row.title}`,
                    sortable: true,
                    width: '40%'
                },
                {
                    name: "Link",
                    sortable: true,
                    cell: (row) => (
                        <a target="_blank" href={row.url}>View Link</a>
                    ),
                    width: '10%'

                },
                {
                    name: "PublishedDate",
                    selector: row => `${row.publishedAt}`,
                    sortable: true,
                    width: '15%'


                },
            ],
            formData:{
                selectedNews:[]
            }

        };
    };

    handleChange =(e, obj) => {
        if (e.target.checked === true)
        {
            this.state.checkedNews.push(obj)
        }else
        {
            this.state.checkedNews.pop(obj)
            // console.log(this.state.checkedNews)
        }

    };

    componentDidMount() {
        this.fetchBazeMedia();
    };

    async fetchBazeMedia() {
        await axios.get(`${serverLink}news/baze-in-the-media`)
            .then(result => {
                const data = result.data;
                const xml = new XMLParser().parseFromString(data);
                const xml_items = xml.children[0].children;
                if (xml_items.length > 0){
                    const news_feed = xml_items.filter(item => item.name === 'item');
                  news_feed.map(x=>{
                    const news_items = x.children;
                    const title = news_items[0].value;
                    const url = news_items[1].value;
                    const publishedAt = news_items[3].value;
                    const All = [{"title":title, "url": url, "publishedAt": publishedAt}]
                    this.state.sendData.push({"title":title, "url": url, "publishedAt": publishedAt});
                    //console.log(All)
                  });
                  //console.log(this.state.sendData)
                  this.setState({
                    data: this.state.sendData

                  })

                }
            }).catch(e => {
                //console.log(e)
            });
        this.setState({isLoading: false})
    };

     handleSubmition = async () => {
        let sendData = {
            ...this.state.formData,
            selectedNews: this.state.checkedNews,
            submittedBy: "Sani Idris"

        };

     await  axios.post(`${serverLink}news/bazemedia`, sendData)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                 if (response === "failed") {
                    this.showAlert("Oops!", "Something went wrong adding the Baba Ahmed News in media. Please try again!", "error")
                    return false;
                } else if (response === "success"){
                    this.showAlert("SUCCESS", "SLIDER ADDED SUCCESSFULLY", "success")
                    return false;
                } else {
                    this.showAlert("Oops!", "Failed to add the Baba Ahmed News in media details!", "error")
                    return false;
                }

            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                console.error('ERROR', err);
            });
    };

    showAlert(title, msg, type){
        return swal({
            title: title,
            text: msg,
            icon: type,
            button: "OK",
        }).then((value) => {
          window.location.href = '#/news/general/bazemedia'
        });
    }


    render() {
        const columns = this.state.columns;
        const data = this.state.data;
        const tableData = {
            columns,
            data,
            print: false,
            exportHeaders: true,
            filterPlaceholder: 'Search ',

        };

        return (
            <>

                <Header isFaculty={this.props.isFaculty ? true : false}/>
                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                    <div className="toolbar" id="kt_toolbar">
                        <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                            <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                                <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">News
                                    <span className="h-20px border-dark  border-start ms-3 mx-2"/>
                                    {this.props.isFaculty  ? "Faculty" : "General"}
                                    <span className="h-20px border-dark  border-start ms-3 mx-2"/>
                                    Baba Ahmedin Media
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="post d-flex flex-column-fluid" id="kt_post">
                        <div id="kt_content_container" className="container-fluid">
                            {/* CONTENT START */}

                            {this.state.isLoading ?
                                <div style={{margin: 'auto', position: 'relative', justifyContent:'center', alignItems: 'center', display: 'flex' , height: '400px'}}>
                                    <Oval
                                        type="Oval"
                                        color="#eeeeee"
                                        height={50}
                                        width={50}
                                        secondaryColor="teal"
                                        timeout={15000} //3 secs
                                    />
                                </div>
                                :
                                <div className="card mb-5 mb-xl-10">
                                    <div className="card-header">
                                        <div className="card-title">
                                            <h3>NEWS LIST</h3>
                                        </div>
                                    </div>

                                    <div className="card-body"  style={{textAlign: 'left'}}>
                                        <DataTableExtensions
                                            {...tableData}
                                        >
                                            <DataTable
                                                noHeader
                                                defaultSortField="id"
                                                defaultSortAsc={false}
                                                highlightOnHover/>
                                        </DataTableExtensions>
                                        <button onClick={this.handleSubmition} type="button" id="kt_modal_new_address_submit" data-kt-indicator={this.props.isLoading} className="btn btn-primary w-25 offset-sm-4 mb-9 mt-9">
                                            <span className="indicator-label" >Submit</span>
                                            <span className="indicator-progress">Please wait...
                                            <span className="spinner-border spinner-border-sm align-middle ms-2"/>
                                        </span>
                                        </button>
                                    </div>

                                </div>}

                            {/* CONTENT END */}
                        </div>
                    </div>


                </div>
            </>
        )
    }
}



export default BazeInMedia;