import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
    setgeneralDetails
} from "../../../actions/setgeneraldetailsaction";
import { serverLink, showAlert, showConfirm } from "../../../resources/url";
import { Oval } from "react-loader-spinner";
import { connect } from "react-redux";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import axios from "axios";
import Header from "../../Header/header";

class SlidersList extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: true,
            columns: [
                {
                    name: "Title",
                    selector: row => `${row.Title}`,
                    sortable: true,
                },

                {
                    name: "Btn Name ",
                    selector: row => `${row.BtnName}`,
                    sortable: true
                },
                {
                    name: "Btn Link ",
                    selector: row => `${row.BtnLink}`,
                    sortable: true
                },
                {
                    name: "Image Position",
                    selector: row => `${row.ImagePosition}`,
                    sortable: true
                },
                {
                    name: "Images",
                    selector: row => <img src={`${serverLink}public/uploads/sliders_uploads/${row.ImagePath}`}
                        className="w-65px me-3" alt="" />,
                    sortable: true
                },
                {
                    name: "View",
                    cell: (row) => (
                        <a href={`babaahmeduniversity.edu.ng/slider/${row.Slug}`} target="_blank" className="btn btn-primary btn-sm">
                            <i className="fa fa-eye" aria-hidden="true" />
                        </a>

                    ),
                },
                {
                    name: "Update",
                    cell: (row) => (
                        <Link to="/general/slider/update">
                            <button
                                type="button"
                                onClick={async () => {
                                    const sliderData = {
                                        ...row
                                    };
                                    this.props.setOnGeneralDetails(sliderData);
                                }}
                                className="btn btn-primary btn-sm"
                            >
                                <i className="fa fa-edit" aria-hidden="true" />
                            </button>
                        </Link>
                    ),
                },
                {
                    name: "Update",
                    cell: (row) => (
                        <button
                            type="button"
                            onClick={async () => { this.onDelete(row) }}
                            className="btn btn-danger btn-sm"
                        >
                            <i className="fa fa-trash" aria-hidden="true" />
                        </button>
                    ),
                },

            ],
            data: []
        }
    }

    componentDidMount() {
        this.getSliderData();
    }

    onDelete = async (dt) => {
        showConfirm("warning", "Are you sure you want to delete slider?", "warning").then( async(confirm) => {
            if (confirm) {
                await axios.delete(`${serverLink}slider/delete/${dt.EntryID}`)
                    .then(data => {
                        const result = data.data;
                        if (result.message === "deleted") {
                            this.getSliderData();
                            showAlert("SUCCESS", "LEADERSHIP DELETED SUCCESSFULLY", "success")

                            return false;
                        }
                    });
            }
        })
    }

    async getSliderData() {
        await axios.get(`${serverLink}slider/homepage`)
            .then(data => {
                const result = data.data;
                this.setState({
                    data: [...result]

                });
            });
        this.setState({ isLoading: false })
    }

    render() {
        const columns = this.state.columns;
        const data = this.state.data;
        const tableData = {
            columns,
            data,
            print: false,
            exportHeaders: true,
            "export": true,
            filterPlaceholder: 'Search Slider',
        };
        return (
            <>
                <Header isFaculty={false} />
                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                    <div className="toolbar" id="kt_toolbar">
                        <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                            <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                                <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">General
                                    <span className="h-20px border-dark  border-start ms-3 mx-2"></span>
                                    Slider
                                    <span className="h-20px border-dark  border-start ms-3 mx-2"></span>
                                    Slider List
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="post d-flex flex-column-fluid" id="kt_post">
                        <div id="kt_content_container" className="container-fluid">
                            {/* CONTENT START */}
                            {this.state.isLoading ?
                                <div style={{
                                    margin: 'auto',
                                    position: 'relative',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                    height: '400px'
                                }}>
                                    <Oval
                                        type="Oval"
                                        color="#eeeeee"
                                        height={50}
                                        width={50}
                                        secondaryColor="teal"
                                        timeout={15000} //3 secs
                                    />
                                </div>
                                :
                                <div className="card mb-5 mb-xl-10">
                                    <div className="card-header">
                                        <div className="card-title">
                                            <h3>SLIDER LIST</h3>
                                        </div>
                                        <span className="mt-5" style={{ float: 'right' }}>
                                            <Link to="/general/slider/addslider" className="btn btn-primary">
                                                <i className="fa fa-plus"></i> Add Slider
                                            </Link>
                                        </span>

                                    </div>

                                    <div className="card-body" style={{ textAlign: 'left' }}>
                                        <DataTableExtensions
                                            {...tableData}
                                        >
                                            <DataTable
                                                noHeader
                                                defaultSortField="id"
                                                defaultSortAsc={false}
                                                pagination
                                                highlightOnHover />
                                        </DataTableExtensions>


                                    </div>

                                </div>}

                            {/* CONTENT END */}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapDisptachToProps = (dispatch) => {
    return {
        setOnGeneralDetails: (p) => {
            dispatch(setgeneralDetails(p));
        },
    };
};


export default connect(null, mapDisptachToProps)(SlidersList);